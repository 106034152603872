(function ($) {
    

    $(document).ready(function () {
        
        
    $('.equal-el').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });

        console.log('funge');

        function accentra()
        {
            $('body.home .accentra').each(function () {
                var lungImg = $(this).innerWidth();
                $(this).next().css({'width': lungImg});
            });
        }

        //fai partire la gallery
        $('#imageGallery').lightSlider({
            gallery: true,
            item: 1,
            loop: true,
            thumbItem: 5,
            slideMargin: 0,
            enableDrag: false,
            currentPagerPosition: 'left',
            onSliderLoad: function (el) {
                el.lightGallery({
                    selector: '#imageGallery .lslide'
                });
            }
        });

        //fai sparire i filtri se mobile 
        function sparisci() {
            totW = $(window).width();

            if (totW < 991) {
                $('.filtri-cont').addClass('nope');
                $('.filtri-cont').css({'display': 'none'});

            } else {
                $('.filtri-cont').css({'display': 'block'});
            }
        }


        //Gestisci il posizionamento delle freccie nello slider
        function freccieSLides() {
            var sliderH = $('.rslides').height();
            var pos = (sliderH / 2) - 25;
            $('body.home .rslides_nav').css({'top': pos});
            $('body.home .rslides_nav.next').addClass('fa fa-angle-right');
            $('body.home .rslides_nav.prev').addClass('fa fa-angle-left');
        }


        $('#gallery-prodotti').lightGallery({
            thumbnail: true
        });

        //Cancella l'ultimo border nella scheda prodotto
        $('body.single-prodotti .down-fascia a:last').find('.down').css({'border': 'none'});



        //Fai partire lo slide in Homepage 
        $('.rslides').responsiveSlides({
            nav: true,
            prevText: " ",
            nextText: " ",
            timeout: 5000,
            speed: 200,
            before: function () {
                $('.box').addClass('down');
            }, // Function: Before callback
            after: function () {
                $('.box').removeClass('down');
            }
        });


        //     Configurazione default slider
        $('#catslider').lightSlider({
            item: 3,
            loop: false,
            slideMove: 1,
            easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
            speed: 600,
            controls: true,
            pager: false,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        item: 3,
                        slideMove: 1,
                        slideMargin: 6
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        item: 2,
                        slideMove: 1
                    }
                }
            ]
        });
        
        //     Configurazione default slider
        $('#prodslider').lightSlider({
            item: 4,
            loop: false,
            slideMove: 1,
            easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
            speed: 600,
            controls: true,
            pager: false,
            adaptiveHeight: false,
            verticalHeight: 700, 
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        item: 2,
                        slideMove: 1,
                        slideMargin: 6
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        item: 1,
                        slideMove: 1
                    }
                }
            ]
        });
        
         $('.popup-with-zoom-anim').magnificPopup({
              type: 'inline',

              fixedContentPos: false,
              fixedBgPos: true,

              overflowY: 'auto',

              closeBtnInside: true,
              preloader: false,
              
              midClick: true,
              removalDelay: 300,
              mainClass: 'my-mfp-zoom-in'
            });
            
          $('.popup-with-move-anim').magnificPopup({
              type: 'inline',

              fixedContentPos: false,
              fixedBgPos: true,

              overflowY: 'auto',

              closeBtnInside: true,
              preloader: false,
              
              midClick: true,
              removalDelay: 300,
              mainClass: 'my-mfp-slide-bottom'
            });


        //attacca la sidebar in alto
        $('.attaccati').stick_in_parent();

        $('.attaccati')
                .on('sticky_kit:bottom', function (e) {
                    $(this).parent().css('position', 'static');
                })
                .on('sticky_kit:unbottom', function (e) {
                    $(this).parent().css('position', 'relative');
                });

        //Apri la modale di informazioni prodotto
        $('.apri-modale').on('click', function () {
            $('.modale').fadeIn(300, function () {
                $('.modale .box').animate({opacity: 1, top: "50px"}, 200);

            });
        });

        //chiudi la modale di informazioni prodotto
        $('.modale .close').on('click', function () {
            $('.modale').fadeOut(300, function () {
                $('.modale .box').animate({opacity: 0, top: "0px"}, 200);

            });
        });

        //Gestisci il menu in slidetoogle 
        $('aside .menu-mob h3').on('click', function () {
            $('aside .menu-mob ul').slideToggle();
        });




        //Gestisci il filtri in slidetoogle 
        $('aside .filtra-prod h3').on('click', function () {
            $('aside .nope').slideToggle();
        });


        //fai partire il menu per mobile 
        $('.navbar-toggle').on('click', function () {
            $('#search-mob').fadeOut(100); //Cerca
            $('.lang-mob .lang_sel_list_horizontal').fadeOut(100);  //Lingua
            $('.navbar-collapse-mob').slideToggle();
        });

        //Gestisci il search per mobile in slidetoggle
        $('.mobsex span.fa-search').on('click', function () {
            $('.lang-mob .lang_sel_list_horizontal').fadeOut(100);  //Lingua
            $('.navbar-collapse-mob').fadeOut(100); //Menu
            $('#search-mob').slideToggle().toggleClass('open');
        });

        $('.mobsex.lang').on('click', function () {
            $('.navbar-collapse-mob').fadeOut(100); //Menu
            $('#search-mob').fadeOut(100); //Cerca
            $('.lang-mob .lang_sel_list_horizontal').slideToggle().toggleClass('open');
        });






        //Gestisci la parte di readomore

        var moretext;
        var lesstext;

        var lang = $('html').attr('lang');

        if (lang === 'it-IT') {

            moretext = "(Leggi tutto)";
            lesstext = "Chiudi";

        } else if (lang === 'en-US') {

            moretext = "(Show more)";
            lesstext = "Close";

        } else {

            moretext = "(En savoir plus)";
            lesstext = "Fermer";

        }


        // Configure/customize these variables.
        var showChar = 100;  // How many characters are shown by default
        var ellipsestext = "...";


        $('aside .more').each(function () {
            var content = $(this).html();

            if (content.length > showChar) {

                var c = content.substr(100, showChar);
                var h = content.substr(showChar, content.length - showChar);

                var html = c + '<span class="moreellipses">' + ellipsestext + '&nbsp;</span><span class="morecontent"><span>' + h + '</span><a href="" class="morelink">' + moretext + '</a></span>';

                $(this).html(html);
            }

        });

        $(".morelink").click(function () {
            if ($(this).hasClass("less")) {
                $(this).removeClass("less");
                $(this).html(moretext);
            } else {
                $(this).addClass("less");
                $(this).html(lesstext);
            }
            $(this).parent().prev().toggle();
            $(this).prev().toggle();
            return false;
        });

        sparisci();
        accentra();
        freccieSLides();
        
        $(window).resize(function() {

        });

        $(window).on('resize', function () {
            equalizeCol();
            sparisci();
            accentra();
            freccieSLides();

        });

    });

})(jQuery); // Fully reference jQuery after this point.